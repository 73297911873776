/* eslint-disable react/prop-types */
import React from 'react'
import Text from '../../../components/Wrapper/Text'

function CardWrapper({title = "",children,customClass=""}) {
  return (
    <div className={`${customClass} card-wrapper h-auto md:h-6/6 lg:h-5/6 border-t border-[#FFB902] w-full flex-1 mt-0 p-2 lg:rounded-lg`}>
      <div className='py-2'>
         <Text title={title}  color="text-[#FFB902] text-xl md:text-4xl text-center"/>
      </div>
      {children}
    </div>
  )
}

export default CardWrapper
