import logo from './assets/logo.png'
import privateMode from "./assets/images/private.svg";
import freePlay from "./assets/images/freePlay.svg";
import quickPlay from "./assets/images/quickPlay.svg";
import avatar from "./assets/images/avatar.svg";
import setting from "./assets/images/setting.svg";
import crossButton from './assets/images/cross.svg'
import musicSvg from './assets/images/music.svg'
import sound from './assets/images/sound.svg'

// select pawns
import wPawn from './assets/images/WPawn.svg';
import bPawn from './assets/images/bPawn.svg'
import rPawn from './assets/images/RandomPawn.svg'

import wallet from './assets/images/wallet.svg'

import ChessLoading from './assets/images/chessloading.gif'
import TheChessLogo from "./assets/images/TheChessLogo.webp";

import createRoom from './assets/images/createRoom.svg'
import joinRoom from './assets/images/joinRoom.svg';
import addUser from './assets/images/addUser.svg'
import Player from './assets/images/User.svg';


// piece images
import b_b from './assets/new_pieace/SVG/b_b.webp';
import b_w from './assets/new_pieace/SVG/b_w.webp';
import k_b from './assets/new_pieace/SVG/k_b.webp';
import k_w from './assets/new_pieace/SVG/k_w.webp';
import n_b from './assets/new_pieace/SVG/n_b.webp';
import n_w from './assets/new_pieace/SVG/n_w.webp';
import p_b from './assets/new_pieace/SVG/p_b.webp';
import p_w from './assets/new_pieace/SVG/p_w.webp';
import q_b from './assets/new_pieace/SVG/q_b.webp';
import q_w from './assets/new_pieace/SVG/q_w.webp';
import r_b from './assets/new_pieace/SVG/r_b.webp';
import r_w from './assets/new_pieace/SVG/r_w.webp';

 export const pieceImages = {
   "b_b" :b_b,
   "b_w" :b_w,
   "k_b" :k_b,
   "k_w" :k_w,
   "n_b" :n_b,
   "n_w" :n_w,
   "p_b" :p_b,
   "p_w" :p_w,
   "q_b" :q_b,
   "q_w" :q_w,
   "r_b" :r_b,
   "r_w" :r_w,
 }

 export const pieceImageUrls = [
    {"url" :b_b},
    {"url" :b_w},
    {"url" :k_b},
    {"url" :k_w},
    {"url" :n_b},
    {"url" :n_w},
    {"url" :p_b},
    {"url" :p_w},
    {"url" :q_b},
    {"url" :q_w},
    {"url" :r_b},
    {"url" :r_w},
 ]

 const images =  {
   logo,
    privateMode,
    freePlay,
    quickPlay,
    avatar,
    setting,
    crossButton,
    musicSvg,
    sound,
    wPawn,
    bPawn,
    rPawn,
    wallet,
    ChessLoading,
    TheChessLogo,
    createRoom,
    joinRoom,
    addUser,
    Player
}

export default images;