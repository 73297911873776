import React, { useEffect, useMemo, useState } from "react";
import CounterButton from "./CounterButton";
import coinIcon from "../../../assets/coins.png";
import Text from "../../../components/Wrapper/Text";
import { useDispatch, useSelector } from "react-redux";
import { setGameObj } from "../../../feature/slice/gameSlice";
import { showToast } from "../../../utils";

let DEFUALT_PRICES = [];
function CoinCounterCard() {
  const {gameMode} = useSelector(state => state.game);
  const dispatch = useDispatch();
  const [roomPrice, setPrice] = useState(1);

  if(gameMode?.name === 'Private') {
    DEFUALT_PRICES = [roomPrice];
  } else {
    DEFUALT_PRICES = [0.25,0.5,1,5,10,20,25,50,100];
  }

  const [price, setPrices] = useState(0);
   
  //** handle increment */
  const handleIncrement = () => {
     setPrices(prev => {
         if(prev  !== DEFUALT_PRICES.length - 1) {
           return prev + 1;
         } 
         return prev;
     })
  }

   //** handle increment */
   const handleDecrement= () => {
    setPrices(prev => {
        if(prev > 0) {
          return prev - 1;
        } 
        return prev;
    })
   }

   const setRoomPrice = (e) => {
     const value = +e.target.value;
     if(value < 0) {
      //  showToast('error', 'Room amount can less then 1');
       return;
     }
     if(value > 1000) {
      // showToast('error', 'Room amount can not greater then 1000');
      return;
    }
    dispatch(setGameObj({feeAmmount: value}))
    setPrice(e.target.value)
   }

    // ** update fee ammount
    useEffect(() => {
      console.warn("DEFUALT_PRICES[price]")
      dispatch(setGameObj({feeAmmount: DEFUALT_PRICES[price]}))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[gameMode?.name,dispatch, price]);

  
  return (
    <div className="counter flex items-center justify-around gap-3 w-11/12  sm:w-10/12 m-auto md:w-8/12 lg:w-full mt-2">
      <CounterButton handleClick={handleDecrement} className={`${gameMode?.name === 'Private' ? 'opacity-25 pointer-events-none' : ''}`}>
        <svg
          width="24"
          height="24"
          fill="#ffffff"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M19 13H5v-2h14v2Z"></path>
        </svg>
      </CounterButton>
      <div className="counter-card rounded-3xl border-2 border-[#673F15] bg-[#FFB900] h-auto flex-1">
          <div className="body p-[.35rem]">
              <div className="counter-card-inner rounded-tl-2xl rounded-tr-2xl h-auto p-2">
                  <div className="flex items-center justify-center gap-2">
                    <div className="w-6 xxl:w-8">
                        <img src={coinIcon} alt={coinIcon} />
                    </div>
                    <Text title="Coins" color="text-white text-xl xxl:text-2xl" />
                  </div>
                  <div className="counter-value bg-white mt-2 w-5/6 rounded-xl border border-[#673F15] m-auto">
                    {
                      gameMode?.name === 'Private' ?   <input type="number" min={1} max="1000" value={roomPrice} onChange={setRoomPrice} className="text-[#db7e1b] border-none outline-none focus:outline-none text-center text-md xl:text-2xl p-0" /> : (
                        <Text title={`${DEFUALT_PRICES[price]}`} color="text-[#db7e1b] text-center text-md xl:text-2xl" />
                      )
                    }
                  </div>
              </div>
          </div>
          <div className="p-2 text-center ">
            <Text title={`${gameMode?.name === 'Private' ? `Entry $${DEFUALT_PRICES[price]}` : `Entry $${DEFUALT_PRICES[price]}`}`} color="counter-text text-[#452300] text-2xl" />
          </div>
      </div>
      <CounterButton handleClick={handleIncrement} className={`${gameMode?.name === 'Private' ? 'opacity-25 pointer-events-none' : ''}`}>
        <svg
          width="24"
          height="24"
          fill="#ffffff"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2Z"></path>
        </svg>
      </CounterButton>
    </div>
  );
}

export default CoinCounterCard;
