import React, { useContext, useEffect, useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  AppBar,
  Box,
  Toolbar,
  makeStyles,
  Button,
  IconButton,
  Hidden,
} from "@material-ui/core";
import ConnectWalletModal from "src/component/ConnectWalletModal";
import SwipeableTemporaryDrawer from "./RightDrawer";
import { AuthContext } from "src/context/Auth";
import { MdDashboard } from "react-icons/md";
import { useHistory, useLocation } from "react-router-dom";
import FirstTimeReferralModal from "src/component/FirstTimeReferralModal";
import Dialog from "@material-ui/core/Dialog";

import Slide from "@material-ui/core/Slide";
import { numberCompactFormat } from "src/utils";
import { currencyUnit } from "src/constants";
import { switchRealPlay } from "src/ApiConfig/ApiConfig";
import FreePlayLoginModal from "src/component/FreePlayLoginModal";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
  },
  toolbar: {
    height: 70,
  },
  logo: {
    marginRight: theme.spacing(2),
  },

  desktopDrawer: {
    position: "absolute",
    right: 80,
    top: 30,
    width: "100%",
    // background: theme.palette.primary.main,
    height: 266,
    [theme.breakpoints.down("sm")]: {
      width: 600,
      right: 0,
    },
    [theme.breakpoints.down("xs")]: {
      width: 300,
      right: 0,
    },
  },
  buttonBoxes: {
    display: "flex",
    alignItems: "center",
  },
}));

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
  const classes = useStyles();

  return (
    <AppBar className={clsx(classes.root, className)} color="default" {...rest}>
      <Toolbar className={classes.toolbar}>
        <TopBarData />
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
};
TopBar.defaultProps = {
  onMobileNavOpen: () => {},
};

export default TopBar;

export function TopBarData() {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const auth = useContext(AuthContext);
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    if (location) {
      window.localStorage.setItem(
        "referralCode",
        location && location?.search?.split("?")[1]
      );
    }
  }, [location]);

  return (
    <>
      <Box flexGrow={1} />
      <Box className={classes.buttonBoxes}>
        <Hidden xsDown>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              window.open(switchRealPlay);
            }}
          >
            Switch To Real Game
          </Button>
          &nbsp;
        </Hidden>
        {auth.userLoggedIn && (
          <Box className="displayCenter" style={{ gap: "5px" }}>
            <Button
              variant="contained"
              className="TopbarButton"
              color="secondary"
              style={{
                whiteSpace: "pre",
                padding: "10px 13px",
                height: "auto",
              }}
            >
              <img src="images/walletIcon.svg" alt="iamge" height="26px" />
              &nbsp;
              {auth?.viewWalletData?.balance
                ? parseFloat(auth?.viewWalletData?.balance).toFixed(2)
                : "0"}{" "}
              {currencyUnit}
            </Button>
            <Box style={{ marginRight: "8px" }}>
              <SwipeableTemporaryDrawer />
            </Box>
          </Box>
        )}
        {auth.userData.userType === "ADMIN" && (
          <IconButton
            onClick={() => {
              localStorage.setItem("ADMIN", true);
              history.push("/admin-dashboard");
            }}
            mr={2}
            style={{
              background: "#f4a91b",
              color: "#fff",
              padding: "4px",
              marginRight: "10px",
            }}
          >
            <MdDashboard />
          </IconButton>
        )}
        {/* {!auth.userLoggedIn && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => setOpen(true)}
          >
            Log In
          </Button>
        )} */}
        {!auth.userLoggedIn && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => auth.setOpenFreePlayModal(true)}
            style={{
              color: "#fff",
              marginRight: "10px",
            }}
          >
            Log In
          </Button>
        )}
      </Box>
      <FreePlayLoginModal
        handleCloseModal={() => auth.setOpenFreePlayModal(false)}
        openModal={auth.openFreePlayModal}
        auth={auth}
      />
      <ConnectWalletModal
        handleCloseModal={() => auth.setOpenMetamask(false)}
        openModal={auth.openMetamask}
        auth={auth}
      />
      <FirstTimeReferralModal
        handleCloseModal={() => auth.setOpenReferralModal(false)}
        openModal={auth.openReferralModal}
        auth={auth}
      />
    </>
  );
}
