import React, { useEffect, useRef } from "react";

function InputRange({value, onChange }) {
  const inputRangeRef = useRef();


  useEffect(() => {
   if(value && inputRangeRef.current) {
       inputRangeRef.current.style.background = `linear-gradient(90deg, hsla(43, 100%, 50%, 1) ${value * 10}%, hsla(225, 89%, 47%, 1) ${value}%)`;
       inputRangeRef.current.style.border = '2px solid #ffffff;'
   }
  },[value])

  const handleChange = (e) =>  {
     const val = e.target.value;
     onChange(val);
  }

  return (
    <>
      <input
        ref={inputRangeRef}
        type="range"
        min={0.01}
        max={10}
        value={value}
        id="range1"
        step="0.01"
        className="range-input"
        onInput={handleChange}
      />
    </>
  );
}

export default InputRange;
