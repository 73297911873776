/* eslint-disable react/prop-types */
import React, { useState } from "react";
import Rodal from "rodal";
import Ribbine from "../../assets/Ribbin.png";
import Victory from '../../assets/victory.svg'
import defeat from '../../assets/defeat.svg'


function WinLossModalWrapper(props) {
  const {looserPlayer} = props;
  console.log("looser",looserPlayer)
  return (
    <Rodal
      visible={props?.modalShow}
      enterAnimation="zoom"
      leaveAnimation="zoom"
      onClose={props?.onClose}
      customStyles={props?.customStyles}
      id={props?.id}
      //   {...props}
    >
      <div className="absolute -top-24 sm:-top-44 lg:-top-28 2xl:-top-40 left-0 win-assets">
        <img src={Ribbine} alt={Ribbine} className="ribbine scale-[1.1]"/>
        <img src={looserPlayer?.winner  ? Victory : defeat} alt={Victory} className="winDefetImg w-4/12 xl:w-5/12 w-48 absolute top-[30%] md:top-[40%] left-1/2 -translate-x-1/2 -translate-y-1/2"/>
        {/* <Text
          title={props?.ribbenText}
          color="text-[#ffca3a] text-4xl font-bolder absolute top-[40%] left-1/2 -translate-x-1/2 -translate-y-1/2"
        /> */}
      </div>
      {props?.children}
    </Rodal>
  );
}

export default WinLossModalWrapper;
