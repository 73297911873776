import React, { createContext, useContext, useEffect, useState } from "react";
import "./main.css";
import { AuthContext } from "../../../context/Auth";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import useParamsChange from "./hooks/useParamsChange";
import { init, restore } from "./Connection/storage";
import "./images";
import { GameAudio } from "./utils/getAudio";
// import { persistor, store } from './redux/store';
import { PersistGate } from "redux-persist/integration/react";
import { persistor } from "../../../redux/store";
import {chessEvents} from "./eventHelper/socketEvents";
import './eventHelper/subscribeEvent.js';
import { socket2 } from "./Connection/socket";
chessEvents();


const savedData = JSON.parse(sessionStorage.getItem("userGameData"));
if (!savedData) {
  init();
} else {
  restore(savedData);
}
export const MyContext = createContext("");
export const gameAudio = new GameAudio();

export const ChessLayout = ({ children }) => {
  const [param, setSearchParam] = useParamsChange({});
  const [loading, setLoading] = useState(false);
  const [WTime, setWTime] = useState(-1);
  const [BTime, setBTime] = useState(-1);
  const auth = useContext(AuthContext);
  const history = useHistory();
  const loc = useLocation();

  console.log("loc",loc)

  useEffect(() => {
    if (!auth.userLoggedIn) {
      history.push({ pathname: "/", search: "login" });
      document.body.style.overflowY = "auto";
    }
  }, [auth.userLoggedIn, history, loc.pathname]);

  return (
    <div className="chess-layout">
       <PersistGate loading={null} persistor={persistor}>
          <MyContext.Provider
            value={{
              searchParams: param,
              setSearchParams: setSearchParam,
              WTime,
              BTime,
              setWTime,
              setBTime,
              setLoading,
              loading,
            }}
            >
            {children}
          </MyContext.Provider>
        </PersistGate>
    </div>
  );
};
