import { useEffect } from "react";

const useScript = (url, delay) => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = url;
    // script.async = true;
    script.crossorigin = "anonymous";

    if (delay) {
      setTimeout(() => {
        document.body.appendChild(script);
      }, 1000);
    } else {
      document.body.appendChild(script);
    }

    return () => {
      document.body.removeChild(script);
    };
  }, [url]);
};

export default useScript;
