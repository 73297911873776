
export var UserGameData = {
  playerSocketId: null,
  botLevel: null,
  isMultiplayer: null,
  newGameRoomId: null,
  selectedPiece: null,
  choosePiece: null,
  botchoosePiece: null,
  isGameStarted: null,
  isGameOver: null,
  "winState": null,
  "userTurn": null,
  gamePlayData: null,
  isTournament : false,
  gameId: null,
  chessMoveHistory: [],
  movehistorys: null,
  chastoDetail: null,
  savedGame: null,
  isDurationEnd : false,
  prevSavedGame: [],
  savedGameWithBot: null,
  cross: null,
  whoMoved : null,
  leftPlayer: 0,
  restored : false,
  isResume : null,
  board : [],
  prevBoard : [],
  turnCount : 0
};

const savedData = JSON.parse(sessionStorage.getItem("userGameData"));
// set data
export const setData = (key, value) => {
  UserGameData[key] = value;
  saveData();
};

// get data
export const getData = (key) => {
  if(sessionStorage?.getItem("userGameData")) {
    const usergameData = JSON.parse(sessionStorage?.getItem("userGameData"));
    return usergameData[key] ?? null;
  }
};

export const resetData = (key, value) => {
  UserGameData[key] = value;
  sessionStorage.setItem("userGameData", JSON.stringify(UserGameData));
};

function DoesDataExists() {
  return sessionStorage.getItem("userGameData");
}

export const saveData = () => {
  sessionStorage.setItem(
    "userGameData",
    JSON.stringify({ ...savedData, ...UserGameData })
  );
};

export const init = () => {
  var dataExists = DoesDataExists();
  console.log("Does Data..>", dataExists);
  if (dataExists) {
  } else {
    saveData();
  }
};

export const restore = (data) => {
  console.log("savedData restore", savedData);
  UserGameData = data;
  sessionStorage.setItem(
    "userGameData",
    JSON.stringify({ ...savedData, ...data })
  );
};