import React from 'react'
import Text from './Text';
import addButton from '../../assets/addButton.png'

function NavContent({icon,text,button = true}) {
  return (
    <div className='w-20 md:w-28 h-7 md:h-8 bg-[#00000080] rounded-full flex justify-center items-center relative'>
       <img src={icon} alt={icon} className='w-7 md:w-6 absolute -left-4 top-1'/>
       <Text title={text} color="text-white text-xs md:text-lg" weight='bold' />
       <img src={addButton} alt={addButton} className={`cursor-pointer w-7 md:w-8 absolute -right-2 top-0 ${button ? 'block' : 'hidden'}`}/>
    </div>
  )
}

export default NavContent;
