import React, { useContext, useEffect } from "react";
import "./teenpatti.css";
import { AuthContext } from "../../../context/Auth";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { socket } from './socket/socket.js'
import { socketEvents } from './socket/socketEvents.js';
import './socket/subscribeEvent.js'
socketEvents();

export const TeenPattiLayout = ({ children }) => {
    const auth = useContext(AuthContext);
    const history = useHistory();
    const loc = useLocation();
  
    useEffect(() => {
      const addGoogleTranslateScript = () => {
        const script = document.createElement('script');
        script.src = "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
        script.async = true;
        script.defer = true;
        document.body.appendChild(script);
      };
  
      window.googleTranslateElementInit = () => {
        new window.google.translate.TranslateElement(
          { pageLanguage: 'en', includedLanguages: 'en,pt,es' },
          'google_translate_element'
        );
      };
      addGoogleTranslateScript();
    }, []);
  
    console.log("loc",loc)
  
    useEffect(() => {
      if (!auth.userLoggedIn) {
        history.push({ pathname: "/", search: "login" });
        document.body.style.overflowY = "auto";
      }
    }, [auth.userLoggedIn, history, loc.pathname]);
    return <div className="teenpatti-layout">{children}</div>;
  };