// const createRoom ='create_room';
export const SocketcreateRoom = "createRoom";
export const SocketjoinRoom = "joinRoom";
export const SocketCheckMs = "checkMs";

export const ScoketleaveRoom = "leaveRoom";

export const SocketreJoinRoom = "reJoinRoom";
export const SocketOnPlayerEnterRoom = "OnPlayerEnterRoom";
export const SocketOnPlayerLeftRoom = "OnPlayerLeftRoom";
export const SocketPlayerList = "PlayerList";
export const SocketRoomListing = "RoomListing";
export const SocketOnRoomListUpdate = "OnRoomListUpdate";

//Raise Event
export const SocketraiseEvent = "raiseEvent";

//Room Properties
export const SocketsetRoomProperties = "setRoomProperties";
export const SocketgetRoomProperties = "getRoomProperties";

export const setRoomPropertiesForLobby = "setRoomPropertiesForLobby";
export const getRoomPropertiesForLobby = "getRoomPropertiesForLobby";

export const SocketOnRoomPropertiesChange = "OnRoomPropertiesChange";
export const SocketchangeRoomBaseProperty = "changeRoomBaseProperty";

//LocalKeys

// const createRoom ='create_room';
export const OnConnectToMaster = "localOnConnectToMaster_teenpatti";
export const createRoom = "localCreateRoom_teenpatti";
export const onCreateRoom = "localOnCreateRoom_teenpatti";
export const onCreateRoomFailed = "localOnCreateRoomFailed_teenpatti";

export const joinRoom = "localjoinRoom_teenpatti";
export const onJoinRoom = "localonjoinRoom_teenpatti";
export const onJoinRoomFailed = "localonJoinRoomFailed_teenpatti";

export const reJoinRoom = "localreJoinRoom_teenpatti";

export const LeaveRoom = "localLeaveRoom_teenpatti";
export const OnLeaveRoom = "localOnLeaveRoom_teenpatti";
export const OnLeaveRoomFailed = "localOnLeaveRoomFailed_teenpatti";

export const OnPlayerEnterRoom = "localOnPlayerEnterRoom_teenpatti";
export const OnPlayerLeftRoom = "localOnPlayerLeftRoom_teenpatti";

export const PlayerList = "localPlayerList_teenpatti";
export const OnPlayerList = "localOnPlayerList_teenpatti";

export const RoomListing = "localRoomListing_teenpatti";
export const OnRoomListing = "localOnRoomListing_teenpatti";
export const OnLocalRoomListUpdate = "OnLocalRoomListUpdate_teenpatti";

//Raise Event
export const raiseEvent = "localraiseEvent_teenpatti";
export const onRaiseEvent = "localOnRaiseEvent_teenpatti";

//Room Properties
export const setRoomProperties = "localsetRoomProperties_teenpatti";
export const getRoomProperties = "localgetRoomProperties_teenpatti";
export const onGetRoomProperties = "localonGetRoomProperties_teenpatti";
export const onLocalGetRoomProperties = "localonGetRoomProperties_teenpatti";

export const localSetRoomProperties = "localSetRoomProperties_teenpatti";
export const localGetRoomProperties = "localGetRoomProperties_teenpatti";
export const OnRoomPropertiesChange = "localOnRoomPropertiesChange_teenpatti";
export const localchangeRoomBaseProperty = "localchangeRoomBaseProperty_teenpatti";

export const setLocalWinner = "setLocalWinner_teenpatti";

//Game Events
export const StartGame = "localStartGame_teenpatti";
