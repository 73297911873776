import { handleLoutFunction } from "src/utils";
import { useEffect, useRef, useContext } from "react";
import { AuthContext } from "src/context/Auth";
import toast from "react-hot-toast";

const LogoutTimer = () => {
  const logoutTimer = useRef(null);
  const auth = useContext(AuthContext);
  const logout = () => {
    toast.error("Session expired!");
    handleLoutFunction({ auth });
  };
  const resetTimer = () => {
    clearTimeout(logoutTimer.current);
    logoutTimer.current = setTimeout(logout, 30 * 60 * 1000); // 30 minutes in milliseconds
  };
  const handleUserActivity = () => {
    resetTimer();
  };
  useEffect(() => {
    if (sessionStorage.getItem("token")) {
      resetTimer();
      const events = [
        "mousedown",
        "keydown",
        "mousemove",
        "scroll",
        "touchstart",
      ];
      const attachEventListeners = () => {
        events.forEach((event) => {
          window.addEventListener(event, handleUserActivity);
        });
      };
      attachEventListeners();
      return () => {
        events.forEach((event) => {
          window.removeEventListener(event, handleUserActivity);
        });
        clearTimeout(logoutTimer.current);
      };
    }
  }, []);

  return null;
};
export default LogoutTimer;
