import {
  Box,
  Button,
  Dialog,
  IconButton,
  Typography,
  makeStyles,
  TextField,
  FormHelperText,
  DialogContent,
} from "@material-ui/core";
import React, { useState } from "react";
import { CgClose } from "react-icons/cg";
import LoadingComp from "./LoadingComp";
import { toast } from "react-hot-toast";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { postAPIHandler } from "src/ApiConfig/service";

const useStyle = makeStyles((theme) => ({
  PlaceBidModalBox: {
    padding: "20px",
    paddingBottom: "8px",
    marginTop: "10px",
    "& h5": {
      color: "#FFF",
      fontSize: "21px",
      fontWeight: "500",
      marginTop: "-7px",
    },
  },
}));

export default function FirstTimeReferralModal({
  handleCloseModal,
  openModal,
  auth,
}) {
  const classes = useStyle();
  const [isLoading, setIsLoading] = useState(false);

  const initialValues = {
    referralCode:
      localStorage.getItem("referralCode") !== "undefined"
        ? localStorage.getItem("referralCode")
        : "",
  };

  const validationSchema = yup.object().shape({
    referralCode: yup
      .string()
      .required("Please enter referral code.")
      .min(6, "Please enter valid referral code."),
  });

  const handleSubmitRefer = async (values) => {
    try {
      setIsLoading(true);
      const referral = values?.referralCode.match(/[^?]+$/)?.[0];
      const response = await postAPIHandler({
        endPoint: "addReffferalCode",
        dataToSend: {
          refferalCode: referral,
        },
      });
      if (response.data.responseCode === 200) {
        toast.success(response?.data?.responseMessage);
        auth.viewUserProfile(sessionStorage.getItem("token"));
        handleCloseModal();
      } else {
        toast.error(response?.data?.responseMessage);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  return (
    <Dialog
      open={openModal}
      maxWidth="xs"
      fullWidth
      className={classes.maindialog}
    >
      <IconButton
        style={{ position: "absolute", right: "0px" }}
        onClick={() => {
          // handleSubmitRefer();
          handleCloseModal();
        }}
        disabled={isLoading}
      >
        <CgClose style={{ color: "#ffffffc7" }} />
      </IconButton>
      <Box className={classes.PlaceBidModalBox}>
        <Typography variant="h5">Do you have referral code.</Typography>
      </Box>
      <DialogContent>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values) => handleSubmitRefer(values)}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
            setFieldValue,
          }) => (
            <Form style={{ padding: "0px", background: "transparent" }}>
              <Box align="start">
                <Typography
                  variant="body1"
                  style={{
                    marginBottom: "7px",
                    fontSize: "13px",
                    color: "#fffc",
                  }}
                >
                  Enter referral code
                </Typography>
                <TextField
                  variant="outlined"
                  fullWidth
                  type="text"
                  name="referralCode"
                  placeholder="Please enter code"
                  value={values.referralCode}
                  error={Boolean(touched.referralCode && errors.referralCode)}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                <FormHelperText error>
                  {touched.referralCode && errors.referralCode}
                </FormHelperText>
              </Box>
              <Box align="center" my={2}>
                <Button variant="contained" color="primary" type="submit">
                  Submit {isLoading && <LoadingComp />}
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}
