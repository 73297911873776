
import React from "react";
import cancelIcon from "../../assets/CancelIcon.png";
import Rodal from "rodal";
import Text from "../Wrapper/Text";
function ModalWrapper(props) {
  // media query for modal
  return (
    <Rodal
      visible={props?.visible}
      enterAnimation={props?.enterAnimation || "slideUp"}
      leaveAnimation={props?.leaveAnimation || "slideDown"}
      onClose={
        props?.id === "private-room-player-modal" ? () => {} : props?.onClose
      }
      customStyles={{
        ...props?.customStyles,
        minWidth : '20rem',
        minHeight : '12rem',
      }}
      id={props?.id || null}
      // showMask={true}
      // {...props}
    >
      {props?.title && (
        <Text
          title={props?.title}
          color="text-[#FFB902] text-2xl text-center uppercase flex justify-center modal-title"
        />
      )}

      {props?.children}

      {/* close button */}
      <div
        className="w-36 scale-75 md:scale-100 absolute -top-16 md:-top-18 -right-16 cursor-pointer"
        onClick={props?.onClose || props?.handleClose}
      >
        <img src={cancelIcon} alt="cancelIcon" />
      </div>
    </Rodal>
  );
}

export default ModalWrapper;
