import {io} from "socket.io-client"
import { showToast } from "../utils";
import { getURL } from "../utils/url";

// options
const option = {
   closeOnBeforeunload:false,
};

const URL = getURL().SOCKET_URL;

// // console.log("process.env.REACT_APP_SOCKET_URL", import.meta.env.VITE_SOCKET_URL);
// ** socket client instance
const socket2 = io('https://chessmultiplayer.betbyheart.com', option);
// const socket2 = io('http://localhost:4000', option);

// Handle socket reconnection event
socket2.on('reconnect', (attemptNumber) => {
  // setConnected(true);
  console.log(`Reconnected after ${attemptNumber} attempts`);
  // setSocketId(socket2.id);
  // Implement logic to sync game state after reconnection
  // e.g., socket2.emit('requestGameState');
});

// Handle reconnection attempts
socket2.on('reconnect_attempt', (attemptNumber) => {
  console.log(`Reconnection attempt ${attemptNumber}`);
});

// Handle reconnection failure
socket2.on('reconnect_failed', () => {
  console.error('Reconnection failed');
  // Notify user or take appropriate action
  showToast('error', 'Reconnection failed, Reload your page or keep your network strong')
});



async function sendRequest(key, dataToSend, callback) {
  socket2.emit(key, dataToSend, callback)
}


socket2.on('pong', function(ms) {
        console.log("latency",ms);
});


// // export
export { socket2,sendRequest };


export const onGameEvent = "onGameEvent"
export const CreateNewChessGame = "createNewGame";
export const GetLobbies = "getLobbies";
export const GetSessionData = "getSessionData";
export const PlayerJoinGame = "playerJoinGame";
export const PlayerJoinedRoom = "playerJoinedRoom";
export const SendTimer = "sendTimer";
export const OpponentMove = "opponentMove";
export const secondPlayeMove = "secondPlayeMove"
export const BotTurn = "botTurn";
export const NewMove = "newMove";
export const StartGame = "startGame";
export const onPlayerLeftRoom = "onPlayerLeftRoom";
export const endGame = "endGame";
export const offerDraw = "offerDraw";
export const takeBack = "takeBack";
export const undoRequestAccept = "undoRequestAccept";
export const gameSessionData = "gameSessionData";
export const restoreGame = "restoreGame";
export const playerRejoin = "playerRejoin";

export const BEST_MOVE = "BEST_MOVE";
export const USED_POWER_UP = "usedPowerUps";
 
export const JOIN_TOURNAMENT = "joinTournament";
export const LEAVE_TOURNAMENT = "leaveTournament";
export const END_TOURNAMENT = "endTournament";
export const START_TOURNAMENT = 'startTournament';
export const PAIR_USER = "pairUser";
export const TOURNAMENT_START_TIME = "tournamentStartTime";
export const SWISS_PAIR_AGAIN = "swissPairAgain";
export const ROUND_COMPLETE = 'roundComplete';

