import React from 'react'

function CounterButton({children,handleClick,className}) {
  return (
    <>
       <button className={`${className} counter-button p-1`} onClick={handleClick}>
         {children}
      </button>
    </>
  )
}

export default CounterButton
