import React from "react";

const DisconnectIcon = () => {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="18" cy="18" r="17.5" stroke="white" stroke-opacity="0.6"/>
    <path d="M28.9995 19.7676C28.9308 20.1122 28.8827 20.4624 28.7897 20.8001C28.5084 21.8244 27.9286 22.636 27.1067 23.2437C26.6555 22.7759 26.2083 22.3117 25.7657 21.8526C25.9697 21.6798 26.2304 21.507 26.4335 21.2791C27.2141 20.4041 27.3678 19.3789 26.9516 18.2982C26.5355 17.2184 25.7068 16.6546 24.5838 16.5782C24.1559 16.549 23.7245 16.5685 23.2949 16.5661C23.2181 16.5657 23.1412 16.5661 23.0378 16.5661C23.1115 14.5413 22.4002 12.9024 20.7756 11.7897C19.1609 10.6835 17.4455 10.5616 15.7064 11.5131C15.2646 11.0573 14.8152 10.5941 14.3586 10.1229C15.0242 9.62033 15.781 9.30625 16.5957 9.14412C18.0405 8.85691 19.4494 8.99218 20.7976 9.61107C22.5574 10.4185 23.7789 11.7647 24.4786 13.6116C24.5824 13.8854 24.6638 14.1703 24.7253 14.457C24.7613 14.6233 24.8314 14.6845 24.9887 14.7058C26.2047 14.8693 27.184 15.4738 27.9578 16.4392C28.4985 17.1137 28.827 17.8901 28.9533 18.7568C28.9636 18.8286 28.9843 18.899 29 18.9703C28.9995 19.2362 28.9995 19.5021 28.9995 19.7676Z" fill="white" fill-opacity="0.6"/>
    <path d="M26.2075 24.796C25.8309 25.1838 25.4421 25.5845 25.0386 26C24.7083 25.6577 24.3717 25.3065 24.0319 24.9582C23.7632 24.6821 23.534 24.3018 23.2086 24.1656C22.8761 24.0257 22.4482 24.122 22.0622 24.122C18.925 24.1211 15.7877 24.1169 12.6505 24.1239C11.5414 24.1262 10.519 23.8469 9.57077 23.2474C7.74712 22.0944 6.73058 19.7782 7.06224 17.601C7.45231 15.037 9.59953 13.0478 12.0878 12.8097C12.1436 12.8046 12.1984 12.7917 12.3 12.7741C11.4326 11.9101 10.5927 11.0735 9.76356 10.2476C10.1644 9.83435 10.5527 9.43365 10.9284 9.04684C16.0187 14.2935 21.1176 19.5494 26.2075 24.796ZM21.3715 22.2371C21.3122 22.1639 21.2879 22.1287 21.2591 22.0986C18.8904 19.6559 16.5211 17.2133 14.1501 14.7729C14.1043 14.7257 14.0315 14.6766 13.9713 14.6775C13.3062 14.6849 12.6348 14.6456 11.9773 14.7262C9.58335 15.0198 8.12057 17.7817 9.16811 20.0182C9.86063 21.4959 11.039 22.2274 12.6235 22.2339C15.4516 22.245 18.2796 22.2371 21.1077 22.2371C21.1778 22.2371 21.2479 22.2371 21.3715 22.2371Z" fill="white" fill-opacity="0.6"/>
    </svg>
    
    
    
  );
};

export default DisconnectIcon;
