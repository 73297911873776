import React from "react";

const HomeIcon = () => {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="18" cy="18" r="17.5" stroke="white" stroke-opacity="0.6"/>
    <path d="M12.9338 10.2203C13.1185 10.2028 13.2992 10.187 13.4798 10.1677C13.9695 10.1156 14.3894 10.4298 14.4422 10.8892C14.4985 11.3789 14.1842 11.7938 13.6898 11.847C12.5461 11.9699 11.4019 12.0887 10.2565 12.1929C9.64363 12.2485 9.20964 11.6662 9.39966 11.0571C9.65771 10.2273 9.92749 9.40111 10.1926 8.5737C10.2706 8.33028 10.3451 8.08569 10.4289 7.84402C10.5902 7.37707 11.043 7.14242 11.4928 7.28695C11.9438 7.43207 12.1755 7.88322 12.0412 8.35778C12.0025 8.49529 11.9591 8.63105 11.9104 8.79255C12.3321 8.56142 12.715 8.33438 13.1121 8.1366C17.374 6.01425 22.6125 6.92592 25.8986 10.3555C27.6275 12.1595 28.6791 14.2953 28.933 16.7781C29.2919 20.2925 28.2075 23.3318 25.7033 25.8322C23.9526 27.58 21.8178 28.5918 19.3616 28.913C18.9265 28.9698 18.4854 28.9879 18.0462 28.9996C17.5271 29.0137 17.14 28.6479 17.1312 28.1652C17.1224 27.6848 17.5031 27.3126 18.0233 27.3068C20.2038 27.281 22.1556 26.6028 23.8435 25.2271C25.7912 23.639 26.9443 21.5898 27.2375 19.0918C27.8023 14.2819 24.6177 9.86396 19.8742 8.8891C17.4855 8.39816 15.2299 8.79957 13.1326 10.0524C13.0699 10.0898 13.01 10.1314 12.9502 10.1729C12.9408 10.1782 12.9408 10.1981 12.9338 10.2203Z" fill="white" fill-opacity="0.6"/>
    <path d="M14.5472 15.2099C14.5167 12.8319 16.3976 11.5264 18.0485 11.5744C19.5616 11.6188 20.9451 12.6031 21.3252 13.9489C21.5821 14.86 21.4401 15.7453 21.097 16.6061C20.795 17.3633 20.3557 18.0321 19.7393 18.5734C18.7734 19.4224 17.3529 19.4845 16.3729 18.6576C15.2234 17.688 14.6141 16.4235 14.5472 15.2099ZM17.9858 13.3333C16.9389 13.3386 16.2076 14.1379 16.3237 15.173C16.4128 15.9671 16.7976 16.6248 17.3506 17.1854C17.7541 17.595 18.262 17.5839 18.6708 17.1713C19.1171 16.7208 19.4226 16.1865 19.5927 15.5756C19.6918 15.2187 19.7364 14.8577 19.6473 14.4931C19.4883 13.8424 18.7488 13.3292 17.9858 13.3333Z" fill="white" fill-opacity="0.6"/>
    <path d="M18.0157 20.4066C18.6238 20.4066 19.2332 20.3774 19.839 20.4125C21.5076 20.5091 22.9163 21.6916 23.3292 23.3072C23.45 23.7812 23.2183 24.2224 22.7673 24.3763C22.3322 24.5255 21.8735 24.3184 21.6777 23.8825C21.6507 23.8228 21.6296 23.7602 21.609 23.6981C21.2841 22.7198 20.5569 22.1639 19.5305 22.1486C18.5048 22.1334 17.479 22.1317 16.4533 22.1492C15.4357 22.1668 14.7091 22.7239 14.39 23.6882C14.2282 24.1774 13.9214 24.429 13.4968 24.4214C12.9262 24.4114 12.5244 23.8912 12.6593 23.3371C13.0851 21.5857 14.6223 20.4107 16.5213 20.3891C17.0192 20.3832 17.5177 20.3885 18.0157 20.3885C18.0157 20.3949 18.0157 20.4008 18.0157 20.4066Z" fill="white" fill-opacity="0.6"/>
    <path d="M9.36449 22.5975C9.68061 22.5986 9.91168 22.7256 10.09 22.9655C10.3944 23.3757 10.7017 23.7836 11.0143 24.1879C11.3181 24.5805 11.284 25.0797 10.9239 25.3863C10.5644 25.6923 10.0395 25.6642 9.7287 25.2851C9.36332 24.8398 9.02081 24.3746 8.68535 23.9064C8.49063 23.6349 8.48536 23.3289 8.64605 23.0363C8.80206 22.7514 9.05307 22.6057 9.36449 22.5975Z" fill="white" fill-opacity="0.6"/>
    <path d="M12.057 26.9223C12.0676 26.1903 12.6775 25.7842 13.2247 26.0247C13.7525 26.2564 14.2739 26.5051 14.7894 26.7632C15.1665 26.9522 15.3255 27.4045 15.1783 27.7954C15.0275 28.1956 14.5847 28.4659 14.19 28.3097C13.5742 28.0657 12.9772 27.7696 12.3907 27.4618C12.1467 27.3337 12.0564 27.0663 12.057 26.9223Z" fill="white" fill-opacity="0.6"/>
    <path d="M7.84374 17.9946C8.26659 17.9987 8.61203 18.3042 8.67654 18.7197C8.7563 19.2305 8.84017 19.7407 8.9299 20.2498C9.01553 20.7326 8.75337 21.1597 8.29181 21.2715C7.86016 21.3762 7.3898 21.1217 7.29303 20.6694C7.16459 20.0725 7.0643 19.4663 7.00389 18.8589C6.9558 18.3703 7.35989 17.9906 7.84374 17.9946Z" fill="white" fill-opacity="0.6"/>
    <path d="M8.07656 16.4305C7.57864 16.4259 7.14992 15.9876 7.26721 15.5025C7.42205 14.8612 7.64022 14.231 7.88713 13.6183C8.04313 13.2321 8.53108 13.0993 8.92285 13.2561C9.31521 13.4129 9.54452 13.8284 9.42429 14.2339C9.25597 14.7991 9.0683 15.3585 8.87007 15.9138C8.7516 16.2433 8.43197 16.434 8.07656 16.4305Z" fill="white" fill-opacity="0.6"/>
    </svg>
    
    
  );
};

export default HomeIcon;
