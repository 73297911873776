
import { publish, subscribe, unsubscribe } from '../../../../CustomEvents/event'

export const LE_fetchedLobbies = "fetchedLobbies"
export const LE_offerDraw = "offerDraw"
export const LE_takeBack = "takeBack";
export const LE_UndoAccept = 'LE_UndoAccept';
export const LE_gameSessionData = 'LE_gameSessionData';
export const LE_playerLeftRoom = 'LE_playerLeftRoom';
export const LE_SentTimer = 'LE_SentTimer';
export const LE_RestoreGame = 'LE_RestoreGame';
export const LE_getRoomData = 'LE_getRoomData';
export const LE_playerMoved = 'LE_playerMoved';
export const LE_skipTurnComplete = 'LE_skipTurnComplete';
export const LE_kingWalkComplete = 'LE_kingWalkComplete';
export const ChargePowerComplete = 'ChargePowerComplete';
export const Ev_BouceBack = 'Ev_BouceBack';
export const REMOVE_OVERPOWER = 'REMOVE_OVERPOWER';

export {
    publish, subscribe, unsubscribe
}