import {
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
  TextField,
  MenuItem,
  makeStyles,
  FormHelperText,
  FormControl,
  Select,
  Grid,
} from "@material-ui/core";
import React, { useState } from "react";
import { CgClose } from "react-icons/cg";
import { Formik, Form } from "formik";
import * as yup from "yup";
import LoadingComp from "./LoadingComp";
import { postAPIHandler } from "src/ApiConfig/service";
import { toast } from "react-hot-toast";

const useStyle = makeStyles((theme) => ({
  maindialog: {
    "& .MuiSelect-iconOutlined": {
      right: "7px",
      color: "#fff",
    },

    "& h5": {
      marginTop: "20px",
      marginBottom: "9px",
      paddingLeft: "23px",
    },
    "& p": {
      fontSize: "14px",
      marginBottom: "8px",
      color: "rgba(255, 255, 255, 0.60)",
    },
  },
}));

export default function ContactUsModal({ openModal, setOpenModal }) {
  const classes = useStyle();
  const [isLoading, setIsLoading] = useState(false);

  const initialValues = {
    selectGame: "Lottery",
    email: "",
    message: "",
  };

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .max(100, "Should not exceeds 100 characters.")
      .email("Please enter a valid email address.")
      .required("Email is required."),
    selectGame: yup.string().required("Game is required."),
    message: yup
      .string()
      .required("Message is required.")
      .min(3, "Please enter atleast 3 characters.")
      .max(600, "You can enter only 600 characters."),
  });

  const handleSubmitContact = async (values) => {
    try {
      setIsLoading(true);
      const response = await postAPIHandler({
        endPoint: "contactUs",
        dataToSend: {
          gameType: values.selectGame.toLocaleUpperCase(),
          email: values.email,
          message: values.message,
        },
      });
      if (response?.data?.responseCode === 200) {
        toast.success(response?.data?.responseMessage);
        setIsLoading(false);
        setOpenModal(false);
      } else {
        setIsLoading(false);
        toast.error(response?.data?.responseMessage);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      toast.error(error?.response?.data?.responseMessage);
    }
  };
  const [isSignUpActive, setIsSignUpActive] = useState(false);
  return (
    <Box>
      <Dialog
        open={openModal}
        onClose={() => !isLoading && setOpenModal(false)}
        maxWidth="md"
        fullWidth
        className={classes.maindialog}
      >
        <IconButton
          style={{ position: "absolute", right: "0px" }}
          onClick={() => !isLoading && setOpenModal(false)}
        >
          <CgClose style={{ color: "#908f8e" }} />
        </IconButton>

        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={6} style={{ background: "#292D4F" }}>
              <Box>
                <Box mt={2} mb={2}>
                  <Typography
                    variant="h4"
                    style={{ textTransform: "uppercase" }}
                  >
                    Contact Us
                  </Typography>
                </Box>

                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={(values) => handleSubmitContact(values)}
                >
                  {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    touched,
                    values,
                    setFieldValue,
                  }) => (
                    <Form style={{ background: "transparent", padding: "0px" }}>
                      <Box mt={1} align="start">
                        <Typography variant="body1">Select Game</Typography>
                        <Box className="SelectorBox">
                          <FormControl
                            fullWidth
                            variant="outlined"
                            className={classes.formControl}
                          >
                            <Select
                              labelId="demo-simple-select-outlined-label"
                              id="demo-simple-select-outlined"
                              name="selectGame"
                              placeholder="Select game."
                              value={values.selectGame}
                              error={Boolean(
                                touched.selectGame && errors.selectGame
                              )}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              disabled={isLoading}
                              MenuProps={{
                                anchorOrigin: {
                                  vertical: "bottom",
                                  horizontal: "left",
                                },
                                transformOrigin: {
                                  vertical: "top",
                                  horizontal: "left",
                                },
                                getContentAnchorEl: null,
                              }}
                            >
                              <MenuItem value={"Lottery"}>Lottery</MenuItem>
                              <MenuItem value={"Derby"}>Derby</MenuItem>
                              <MenuItem value={"H2H"}>H2H</MenuItem>
                            </Select>
                            <FormHelperText error>
                              {touched.selectGame && errors.selectGame}
                            </FormHelperText>
                          </FormControl>
                        </Box>
                      </Box>
                      <Box mt={2} align="start">
                        <Typography variant="body1">Email</Typography>
                        <TextField
                          variant="outlined"
                          fullWidth
                          type="email"
                          name="email"
                          placeholder="Please enter email address"
                          value={values.email}
                          error={Boolean(touched.email && errors.email)}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          disabled={isLoading}
                        />
                        <FormHelperText error>
                          {touched.email && errors.email}
                        </FormHelperText>
                      </Box>
                      <Box mt={2} align="start" className="multilineField">
                        <Typography variant="body1">Message</Typography>
                        <TextField
                          variant="outlined"
                          fullWidth
                          type="text"
                          name="message"
                          multiline
                          placeholder="Type Something....."
                          value={values.message}
                          error={Boolean(touched.message && errors.message)}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          rows="6"
                          disabled={isLoading}
                        />

                        <Box className="displaySpacebetween">
                          <FormHelperText error>
                            {touched.message && errors.message}
                          </FormHelperText>
                          <Typography variant="subtitle1">
                            {values.message.length}/600
                          </Typography>
                        </Box>
                      </Box>

                      <Box align="center" mt={1} mb={2}>
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                          disabled={isLoading}
                        >
                          SUBMIT {isLoading && <LoadingComp />}
                        </Button>
                      </Box>
                    </Form>
                  )}
                </Formik>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <img src="/images/contactus.svg" />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
